@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

label {
  display: block;
  margin-bottom: 0.25em;
  color: $text-color;
  cursor: pointer;
  font-size: $type-size-6;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
    color: #333;
    font-size: 0.9em
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    font-size: 0.8em;
}

.masthead__menu-item {
  font-size: 0.7em;
}

.social-icons {
  font-size: 0.7em;
  a {
    white-space: nowrap;
  }
}

// Blocks of code
pre.highlight {
  color: #ffffff;
  code {
    color: #ffffff;
  }
}

table.dataTable tbody tr:nth-child(even){
  background-color: #eee;
}


table.dataTable  {
  font-family: Verdana;
  font-size: 12px;
  border-collapse: collapse;
  width: 600px;
}

table.dataTable td, th {
  padding: 10px;
  text-align: left;
  margin: 0;
}

table.dataTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #337ab7;
}

table.dataTable td:nth-child(1) {
  font-weight: bold
}

.dataTables_wrapper .dataTables_length {
    float: left;
}

/* tell the container's children to float left: */
.float-my-children > * {
    float:left;
    margin-right:5px;
}

/* this is called a clearfix. it makes sure that the container's children floats are cleared, without using extra markup */

.clearfix {
    *zoom:1 /* for IE */
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/* end clearfix*/

.page__footer-follow {
  font-size: 3em;

  li {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: $sans-serif-narrow;
    font-size: $type-size-6;
    text-transform: uppercase;
  }
}

button.dt-button, div.dt-button, a.dt-button {
  font-size: 0.45em;
  margin-top: 1em !important;
  margin-bottom: 0px !important;
}

.container {
    //outline: 1px solid #ed862a;
    width: 100%;
    .box-width.percentage-var {
        width: 70%; // half %
        margin: auto;
    }
}
